import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  VisbilityContainer,
  CommonPose
} from '../components/animations'

const Description = props => (
  <VisbilityContainer>
    <div className="product-description container">
      <div className="c1">
        <CommonPose>
          <h2>
            ChalicePay
            makes billing your best friend.<br />
          </h2>
        </CommonPose>
        <CommonPose>
          Say goodbye to invoicing headaches and hello to the fast, efficient
          future of online billing and transactions. Whether streamlining your
          billing or moving towards a subscription model, ChalicePay gives you a
          platform to take your business to the next level.
        </CommonPose>
        <CommonPose className="action-row">
          <a href={process.env.SIGNUP_URL} className="btn btn-blue">Sign Up</a>
        </CommonPose>
      </div>
      <div className="c2">
        <StaticQuery
          query={graphql`
            query DescQuery {
              contentfulAsset(title: {eq: "best-friend"})
              {
                fluid(maxWidth: 500, resizingBehavior: SCALE) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          `}
          render={ data => (
            <Img alt="" fluid={data.contentfulAsset.fluid} />
          )} />
      </div>
    </div>
  </VisbilityContainer>
)

export default Description
