import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Carousel from '../components/carousel'

import {
  VisbilityContainer,
  CommonPose
} from '../components/animations'

const Features = props => (
  <StaticQuery
    query={graphql`
      query FeatureQuery {
        contentfulHome {
          featureHeader
          featureSubtitle
        }
        allContentfulFeatureCarousel(sort: { fields: [order]}) {
          edges {
            node {
              title
              subtitle
              image {
                fluid(maxWidth: 500, resizingBehavior: SCALE) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={ data => {
      const { featureHeader, featureSubtitle } = data.contentfulHome
      return (
        <VisbilityContainer>
          <div className="product-features">
            <div className="container">
              <CommonPose>
                <h2>{ featureHeader } </h2>
              </CommonPose>
              <CommonPose className="features-description">
                { featureSubtitle }
              </CommonPose>
              <div>
                <CommonPose>
                  <Carousel>
                    { data.allContentfulFeatureCarousel.edges.map( ({ node }, i) => (
                    <div key={i}>
                      <h3>{ node.title }</h3>
                      <div className="description">{ node.description }</div>
                      <Img alt={node.title} fluid={node.image.fluid} />
                    </div>
                    )) }
                  </Carousel>
                </CommonPose>
                <CommonPose className="center">
                  <a href={process.env.SIGNUP_URL} className="btn btn-blue">Sign Up</a>
                </CommonPose>
              </div>
            </div>
          </div>
        </VisbilityContainer>
      )
    }} />
)

export default Features
