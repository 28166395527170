import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"

const CustomCarousel = (props) => {
  const indicatorStyles = {
      background: '#ccc',
      width: 50,
      height: 8,
      display: 'inline-block',
      margin: '0px 8px',
  };

  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      showStatus={false}
      statusFormatter={(current, total) => `Current slide: ${current} / Total: ${total}`}
      renderArrowPrev={(onClickHandler, hasPrev, label) => null}
      renderArrowNext={(onClickHandler, hasNext, label) => null}
      renderThumbs={(children) => null}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: 'rgba(0, 113, 206, 1)' }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}>
      {props.children}
    </Carousel>
  );
};

export default CustomCarousel
